import React, { Component } from 'react'
import {
  Container,
  Grid,
  Header,
  Card,
  Button,
  List,
  Responsive,
  Accordion,
  Segment,
  Icon
} from 'semantic-ui-react'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import { graphql, navigate } from 'gatsby'
import { withTranslation } from 'react-i18next'

import Layout from '../components/Layouts/PageLayout'
import TextLong from '../components/Common/TextLong'
import Videos from '../components/Common/Videos'
import ItemSlider from '../components/Items/ItemSlider'
import ImageHeaderSlider from '../components/Items/ImageHeaderSlider'
import PageHeader from '../components/Items/PageHeader'

import { getFluidImage, getRawImage } from '../utils/images'
import { computerBreakpoint } from '../utils/constants'
import { localizeLink } from '../utils/helpers'

const ITEM_LIMIT = 6

class CountryPage extends Component {
  state = { showAll: false }

  handleShowAll = () => this.setState({ showAll: true })

  handleLocationRedirect = ({
    title,
    countrySlug,
    region,
    regionSlug,
    countryRegionSlug,
    slug
  }) => {
    const { i18n } = this.props

    if (region === 'Bali') {
      title = undefined
    }
    navigate(
      localizeLink(
        `/country-regions/${countryRegionSlug}/destinations/${countrySlug}/all-services/regions/${regionSlug}/locations/${slug}`,
        i18n.language
      )
    )
  }

  handleServiceRedirect = service => {
    const { i18n } = this.props

    const { data } = this.props
    const { countrySlug, countryRegionSlug } = data.firestoreCountry

    navigate(
      localizeLink(
        `/country-regions/${countryRegionSlug}/destinations/${countrySlug}/${service}`,
        i18n.language
      )
    )
  }

  render () {
    const { data, pageContext, t } = this.props
    const { showAll } = this.state
    const items = data.allFirestoreHotel ? data.allFirestoreHotel.edges : []
    const locations = data.allFirestoreLocation
      ? data.allFirestoreLocation.edges
      : []
    let locationsLtd = showAll ? locations : locations.slice(0, ITEM_LIMIT)

    const baliLocations = locationsLtd.filter(
      ({ node: { region } }) => region === 'Bali'
    )
    if (baliLocations.length) {
      const locationsWithoutBali = locationsLtd.filter(
        ({ node: { region } }) => region !== 'Bali'
      )
      const bali = {
        node: {
          ...baliLocations[0].node,
          title: 'Bali',
          cities: [...baliLocations]
        }
      }
      locationsLtd = [...locationsWithoutBali, bali]
    }

    const {
      title,
      lead,
      eyesWatch,
      eyesWord,
      population,
      surface,
      capital,
      language,
      localtime,
      currency,
      guide,
      climate,
      entry,
      images,
      slug,
      countryRegionSlug,
      seo,
      videos
    } = data.firestoreCountry

    const panels = [
      {
        title: t('country-page.panels.info'),
        content: {
          content: (
            <Container>
              <List bulleted>
                <List.Item>
                  <strong>{t('country-page.panels.population')}:</strong>{' '}
                  <span dangerouslySetInnerHTML={{ __html: population }} />
                </List.Item>
                <List.Item>
                  <strong>{t('country-page.panels.area')}:</strong>{' '}
                  <span dangerouslySetInnerHTML={{ __html: surface }} />
                </List.Item>
                <List.Item>
                  <strong>{t('country-page.panels.capital')}:</strong>{' '}
                  <span dangerouslySetInnerHTML={{ __html: capital }} />
                </List.Item>
                <List.Item>
                  <strong>{t('country-page.panels.language')}:</strong>{' '}
                  <span dangerouslySetInnerHTML={{ __html: language }} />
                </List.Item>
                <List.Item>
                  <strong>{t('country-page.panels.time')}:</strong>{' '}
                  <span dangerouslySetInnerHTML={{ __html: localtime }} />
                </List.Item>
                <List.Item>
                  <strong>{t('country-page.panels.currency')}:</strong>{' '}
                  <span dangerouslySetInnerHTML={{ __html: currency }} />
                </List.Item>
                <List.Item>
                  <strong>{t('country-page.panels.gratuities')}:</strong>{' '}
                  <span dangerouslySetInnerHTML={{ __html: guide }} />
                </List.Item>
              </List>
            </Container>
          )
        }
      },
      {
        title: t('country-page.panels.climate'),
        content: {
          content: (
            <Container>
              <div
                dangerouslySetInnerHTML={{
                  __html: climate.replace(/(\n)+/g, '<br />')
                }}
              />
            </Container>
          )
        }
      },
      {
        title: t('country-page.panels.requirements'),
        content: {
          content: (
            <Container>
              <div
                dangerouslySetInnerHTML={{
                  __html: entry.replace(/(\n)+/g, '<br />')
                }}
              />
            </Container>
          )
        }
      }
    ]

    return (
      <>
        <Helmet>
          <title>{seo.info.htmlTitle}</title>

          <meta name='description' content={seo.info.description} />
          <meta
            name='image'
            content={getRawImage({ cloudinary: images.headers[0].cloudinary })}
          />

          <meta property='og:title' content={title} />
          <meta property='og:description' content={seo.info.description} />
          <meta
            property='og:image'
            content={getRawImage({ cloudinary: images.headers[0].cloudinary })}
          />

          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:creator' content='Tischler Reisen' />
          <meta name='twitter:title' content={title} />
          <meta name='twitter:description' content={seo.info.description} />
          <meta
            name='twitter:image'
            content={getRawImage({ cloudinary: images.headers[0].cloudinary })}
          />
        </Helmet>

        <Layout pageContext={pageContext}>
          {images && images.headers.length > 0 && (
            <ImageHeaderSlider images={images.headers} />
          )}

          <PageHeader title={seo.info.h1} />

          <Segment vertical padded='very' basic>
            <Container>
              <Grid>
                <Grid.Column
                  computer={11}
                  tablet={16}
                  className='country-content'
                >
                  <Segment vertical padded='very'>
                    <TextLong text={lead} breakpoint={computerBreakpoint} />
                  </Segment>

                  {videos && videos.length > 0 && (
                    <Videos videos={videos} slider />
                  )}

                  <Header as='h3' color='red'>
                    {t('country-page.regions')}
                  </Header>

                  <Card.Group itemsPerRow={3} doubling>
                    {locationsLtd.map(({ node }) => (
                      <Card
                        as='div'
                        onClick={() => this.handleLocationRedirect(node)}
                        key={node.id}
                        style={{ cursor: 'pointer' }}
                      >
                        <Img
                          loading='lazy'
                          fadeIn={false}
                          fluid={getFluidImage({
                            cloudinary: node.coverImage,
                            maxWidth: 500
                          })}
                          alt={node.alt || node.title}
                        />
                        <Card.Content>
                          <Card.Header>{node.title}</Card.Header>
                        </Card.Content>
                      </Card>
                    ))}
                  </Card.Group>

                  {locations.length > ITEM_LIMIT && !showAll && (
                    <Segment padded='very' vertical textAlign='center'>
                      <Button
                        size='large'
                        color='red'
                        icon
                        labelPosition='right'
                        inverted
                        onClick={this.handleShowAll}
                      >
                        {t('country-page.show-all')}
                        <Icon name='plus' />
                      </Button>
                    </Segment>
                  )}

                  <Segment vertical basic padded='very'>
                    <Header as='h3' color='red'>
                      {t('country-page.extras.eyes')}
                    </Header>
                    <TextLong
                      text={eyesWatch}
                      breakpoint={computerBreakpoint}
                    />
                    <TextLong text={eyesWord} breakpoint={computerBreakpoint} />
                  </Segment>
                </Grid.Column>

                <Grid.Column computer={5} tablet={16} className='insertHtml'>
                  <Segment vertical basic padded='very'>
                    <Responsive
                      maxWidth={computerBreakpoint - 1}
                      className='tabs-wrapper'
                    >
                      <Accordion defaultActiveIndex={0} panels={panels} fluid />
                    </Responsive>

                    <Responsive minWidth={computerBreakpoint}>
                      <Header as='h2'>{t('country-page.know')}</Header>
                      <Segment vertical padded basic>
                        <Header as='h4' dividing color='red'>
                          {t('country-page.panels.info')}
                        </Header>
                        <List bulleted>
                          <List.Item>
                            <strong>
                              {t('country-page.panels.population')}:
                            </strong>{' '}
                            <span
                              dangerouslySetInnerHTML={{ __html: population }}
                            />
                          </List.Item>
                          <List.Item>
                            <strong>{t('country-page.panels.area')}:</strong>{' '}
                            <span
                              dangerouslySetInnerHTML={{ __html: surface }}
                            />
                          </List.Item>
                          <List.Item>
                            <strong>{t('country-page.panels.capital')}:</strong>{' '}
                            <span
                              dangerouslySetInnerHTML={{ __html: capital }}
                            />
                          </List.Item>
                          <List.Item>
                            <strong>
                              {t('country-page.panels.language')}:
                            </strong>{' '}
                            <span
                              dangerouslySetInnerHTML={{ __html: language }}
                            />
                          </List.Item>
                          <List.Item>
                            <strong>{t('country-page.panels.time')}:</strong>{' '}
                            <span
                              dangerouslySetInnerHTML={{ __html: localtime }}
                            />
                          </List.Item>
                          <List.Item>
                            <strong>
                              {t('country-page.panels.currency')}:
                            </strong>{' '}
                            <span
                              dangerouslySetInnerHTML={{ __html: currency }}
                            />
                          </List.Item>
                          <List.Item>
                            <strong>
                              {t('country-page.panels.gratuities')}:
                            </strong>{' '}
                            <span dangerouslySetInnerHTML={{ __html: guide }} />
                          </List.Item>
                        </List>
                      </Segment>
                      <Segment vertical padded basic>
                        <Header as='h4' dividing color='red'>
                          {t('country-page.panels.climate')}
                        </Header>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: climate.replace(/(\n)+/g, '<br />')
                          }}
                        />
                      </Segment>
                      <Segment vertical padded basic>
                        <Header as='h4' dividing color='red'>
                          {t('country-page.panels.requirements')}
                        </Header>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: entry.replace(/(\n)+/g, '<br />')
                          }}
                        />
                      </Segment>
                    </Responsive>
                  </Segment>
                </Grid.Column>
              </Grid>
            </Container>
          </Segment>

          {!!items.length && (
            <ItemSlider
              label={{
                black: t('country-page.carousel.black'),
                red: `${title} ${t('country-page.carousel.red')}`
              }}
              items={items}
              service='hotels'
              countrySlug={slug}
              countryRegionSlug={countryRegionSlug}
            />
          )}
        </Layout>
      </>
    )
  }
}

export default withTranslation()(CountryPage)

/**************************************************************
 * GRAPHQL
 **************************************************************/
export const pageQuery = graphql`
  query CountryQuery($countrySlug: String!, $lang: String!) {
    firestoreCountry(slug: { eq: $countrySlug }, lang: { eq: $lang }) {
      id
      lang
      title
      slug
      countryRegionSlug
      lead
      eyesWatch
      eyesWord
      population
      surface
      capital
      language
      localtime
      currency
      guide
      climate
      seo {
        info {
          htmlTitle
          description
          h1
        }
      }
      entry
      images {
        headers {
          id
          cloudinary
          transformation
          alt
        }
      }
    }
    allFirestoreLocation(
      filter: { countrySlug: { eq: $countrySlug }, lang: { eq: $lang } }
      sort: { fields: [jsonOrder], order: ASC }
    ) {
      edges {
        node {
          id
          lang
          title
          slug
          countryRegionSlug
          country
          countrySlug
          region
          regionSlug
          coverImage
          jsonOrder
        }
      }
    }
    allFirestoreHotel(
      limit: 10
      filter: { countrySlug: { eq: $countrySlug }, lang: { eq: $lang } }
    ) {
      edges {
        node {
          id
          lang
          title
          country
          region
          location
          intro
          collection
          coverImage
          slug
          countrySlug
          countryRegionSlug
        }
      }
    }
  }
`
